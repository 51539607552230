<template>
<div class="deliver-material">
  
  <div class="card">
    <div class="common-title">
      <span>{{$t('牌照号码登记')}}</span>
    </div>
    <!-- 车牌号输入 -->
    <VnpInput v-model="licensePlate" class="mt10" />
  </div>
  <div class="card">
    <div class="common-title">
      <span>{{$t('车辆行驶证上传')}}</span>
    </div>
    <van-uploader class="upload mt10" capture="camera" accept="image/*" :preview-options="{ closeable: true }"
      :before-read="beforeRead" :after-read="(file) => afterRead(file)" :file-list="fileList"
      :before-delete="(file) => beforeDelete(file)" :max-count="2" multiple />
  </div>
  <div class="common-footer">
    <van-button class="btn back" native-type="button" @click.stop="goBack">{{ $t('返回') }}</van-button>
    <van-button class="btn submit" type="submit" @click="onSubmit">{{ $t('提交') }}</van-button>
  </div>
</div>
</template>
<script>
import { mapGetters } from 'vuex'
import deliveryServices from '@/services/deliveryServices.js'
import loading from '@/utils/loading'
import { uploadFile } from '@/utils/cos'
import { v4 as uuidv4, validate } from 'uuid'
import VnpInput from '@/components/VehicleKeyboard/vnp-input.vue'
export default {
  components: {
    VnpInput
  },
  data() {
    return {
      taskStatus: '',
      operation: '',
      fileList: [],
      params: {
        id: '',
        taskStatus: '4004003',
        executionJson: {
          taskCode: 'LICENSE_APPLICATION',
          licensePlate: '', // 车牌号
          drivingLicenseUrl: '' // 行驶证
        },
      },
      licensePlate: '', // 车牌号
    }
  },
  computed: {
  },
  
  mounted() {
    const { taskId, operation,taskStatus } = this.$route.query
    this.params.id = taskId
    this.operation = operation
    this.taskStatus = taskStatus
    if (this.taskStatus === '4004003') {
      this.getTaskDetail()
    }
  },
  methods: {
    // 获取任务详情
    async getTaskDetail() {
      const params = {
        taskId: this.params.id,
      }
      const res = await deliveryServices.deliveryTaskDetail(params)
      // 回显数据
      if (res.executionJson?.licensePlate) this.licensePlate = res.executionJson.licensePlate
      if (res.executionJson?.drivingLicenseUrl){
        const urlArr = res.executionJson.drivingLicenseUrl.split(',')
        this.fileList = urlArr.map(url=>{
          return {
            url,
            cosUrl: url,
            message: '',
            status: 'done',
            uuid: uuidv4()
          }
        })
      }
    },
    
    // 图片校验
    beforeRead(file) {
      // const str = file.name.substring(file.name.lastIndexOf('.') + 1)
      // const rules = ['jpg', 'png', 'gif']
      // if (!rules.includes(str.toLocaleLowerCase())) {
      //   this.$toast.fail(this.$t('请上传正确图片文件(支持jpg/png/gif)'))
      //   return false
      // }
      if (!file.type.includes('image')) {
        this.$toast.fail(this.$t('请上传正确图片文件'))
        return false
      }
      return true
    },
    afterRead(file) {
      const uuid = uuidv4()
      const content = file.content
      file.status = 'uploading'
      file.message = this.$t('上传中...')
      file.uuid = uuid
      uploadFile(file.file).then(({ url }) => {
        if (file) {
          file.status = 'done'
          file.message = ''
          file.url = content
          file.cosUrl = url
          this.fileList.push(file)
        }
      }).catch(() => {
        file.status = 'failed'
        file.message = this.$t('上传失败')
      })
    },
    beforeDelete(file) {
      this.fileList = this.fileList.filter(item => item.uuid !== file.uuid)
    },
    goBack() {
      this.$router.go(-1)
    },
    async onSubmit() {
      
      // 代为办理时，车牌号必填
      if (!this.licensePlate){
        this.$toast.fail(this.$t('请输入牌照号码'))
        return
      }
      this.params.executionJson.licensePlate = this.licensePlate
      if (this.fileList.length){
        const urlArr = this.fileList.map(item=> item.cosUrl)
        this.params.executionJson.drivingLicenseUrl = urlArr.join(',')
      }
      
      loading.showLoading()
      const res = await deliveryServices.deliveryExecution(this.params)
      loading.hideLoading()
      if (res.code === 0) {
        setTimeout(() => {
          this.$router.go(-1)
        }, 1000)
      } else {
        this.$toast(res.msg)
      }
    }
  }

}
</script>
<style lang="less" scoped>
.deliver-material {
  padding: 16px;
  padding-bottom: 96px;

  .card {
    padding: 0 12px 10px;
    background: #FFFFFF;
    box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.05);
    border-radius: 8px 8px 8px 8px;
    opacity: 1;
    margin-bottom: 10px;

    .sub-title {
      padding: 12px 0;
    }
  }
}

.radio-group {
  height: 120px;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.mt10 {
  margin-top: 10px;
}
</style>