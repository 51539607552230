<template>
  <div>
    <VnpInputBox
      :value="val"
      @click.native="show = true"
    ></VnpInputBox>
    <VnpKeyboard
      v-model="val"
      :show.sync="show"
    ></VnpKeyboard>
  </div>
</template>

<script>
import VnpInputBox from './vnp-input-box.vue'
import VnpKeyboard from './vnp-keyboard.vue'

export default {
  name: 'VnpInput',
  components: {
    VnpInputBox,
    VnpKeyboard
  },
  props: {
    value: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      show: false,
    }
  },
  computed: {
    val: {
      set(v) {
        this.$emit('input', v)
      },
      get() {
        return this.value
      },
    },
  },
}
</script>