import { render, staticRenderFns } from "./vnp-keyboard.vue?vue&type=template&id=fed0d802&scoped=true"
import script from "./vnp-keyboard.vue?vue&type=script&lang=js"
export * from "./vnp-keyboard.vue?vue&type=script&lang=js"
import style0 from "./vnp-keyboard.vue?vue&type=style&index=0&id=fed0d802&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fed0d802",
  null
  
)

export default component.exports